var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-row', {
    staticClass: "row--board-event"
  }, _vm._l(_vm.boards, function (item) {
    var _item$meta, _item$meta2, _item$meta3;
    return _c('v-col', {
      key: item._id,
      attrs: {
        "cols": "6",
        "md": "4"
      }
    }, [_c('router-link', {
      attrs: {
        "to": `${_vm.$route.path}/${item._id}`
      }
    }, [_c('div', {
      staticClass: "mb-20 mb-md-30"
    }, [_c('v-img', {
      staticClass: "rounded-xl",
      attrs: {
        "src": item.thumb,
        "width": "100%",
        "aspect-ratio": 1 / 1
      }
    }, [!((_item$meta = item.meta) !== null && _item$meta !== void 0 && _item$meta.isOnGoing) ? _c('v-overlay', {
      attrs: {
        "absolute": "",
        "color": "black"
      }
    }) : _vm._e()], 1)], 1), (_item$meta2 = item.meta) !== null && _item$meta2 !== void 0 && _item$meta2.isOnGoing ? _c('div', {
      staticClass: "mb-10"
    }, [_c('span', {
      staticClass: "font-size-14 font-size-md-16 primary--text"
    }, [_vm._v("진행 중")])]) : _vm._e(), !((_item$meta3 = item.meta) !== null && _item$meta3 !== void 0 && _item$meta3.isOnGoing) ? _c('div', {
      staticClass: "mb-10"
    }, [_c('span', {
      staticClass: "font-size-14 font-size-md-16 grey-7--text"
    }, [_vm._v("종료")])]) : _vm._e(), _c('div', {
      staticClass: "mb-2"
    }, [_c('strong', {
      staticClass: "font-size-18 font-size-md-24 grey-2--text"
    }, [_vm._v(_vm._s(item.subject))])]), _c('div', [_c('span', {
      staticClass: "font-size-14 grey-a--text"
    }, [_vm._v(_vm._s(item.period))])])])], 1);
  }), 1), _vm._t("pagination")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }