<template>
    <div>
        <v-row class="row--board-event">
            <v-col cols="6" md="4" v-for="item in boards" :key="item._id">
                <router-link :to="`${$route.path}/${item._id}`">
                    <div class="mb-20 mb-md-30">
                        <v-img :src="item.thumb" width="100%" :aspect-ratio="1 / 1" class="rounded-xl">
                            <v-overlay v-if="!item.meta?.isOnGoing" absolute color="black" />
                        </v-img>
                    </div>
                    <div v-if="item.meta?.isOnGoing" class="mb-10"><span class="font-size-14 font-size-md-16 primary--text">진행 중</span></div>
                    <div v-if="!item.meta?.isOnGoing" class="mb-10"><span class="font-size-14 font-size-md-16 grey-7--text">종료</span></div>
                    <div class="mb-2"><strong class="font-size-18 font-size-md-24 grey-2--text">{{ item.subject }}</strong></div>
                    <div><span class="font-size-14 grey-a--text">{{ item.period }}</span></div>
                </router-link>
            </v-col>
        </v-row>
        <slot name="pagination"></slot>
    </div>
</template>

<script>
import PaginationComponent from "@/components/client/control/pagination-component.vue";
export default {
    components: { PaginationComponent },
    props: {
        summary: {
            type: Object,
            default() {
                return { totalCount: 0 };
            },
        },
        boards: { type: Array, default: null },
        page: { type: Number, default: 1 },
        skip: { type: Number, default: 0 },
        limit: { type: Number, default: null },
    },
    methods: {
        changePage(page) {
            this.$emit("page", page);
        },
    },
    computed: {
        count() {
            return Math.ceil(this.summary.totalCount / this.limit);
        },
    },
};
</script>

<style scoped>
.row--board-event{
    margin:-14px -10px;
}
.row--board-event>[class*=col]{
    padding:14px 10px;
}
@media (min-width:576px){
}
@media (min-width:768px){
    .row--board-event{
        margin:-30px -18px;
    }
    .row--board-event>[class*=col]{
        padding:30px 18px;
    }
}
@media (min-width:1024px){
}
@media (min-width:1200px){
}
</style>
